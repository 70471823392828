<template>
   <TherapistManageAvailability>
      <div class="flex justify-end gap-2 sm:hidden my-3">
         <div
            class="flex rounded text-xs bg-orange-50 p-0 pt-0 h-10 px-3 gap-2 items-center"
         >
            <span class="bg-orange-500 rounded-full h-3 w-3"></span>
            <span class="text-xs sm:text-sm">Today</span>
         </div>
         <div class="flex rounded bg-primary-50 h-10 p-2 gap-2 items-center">
            <span class="bg-primary-500 rounded-full h-3 w-3"></span>
            <span class="text-xs sm:text-sm">Booked</span>
         </div>
         <div class="flex rounded bg-green-50 h-10 p-2 gap-2 items-center">
            <span class="bg-green-500 rounded-full h-3 w-3"></span>
            <span class="text-xs sm:text-sm">Off Time</span>
         </div>
      </div>
      <div v-if="!showFullCalendar">
         <CalendarDay
            :key="reload"
            @event-clicked="showSessionOverview"
         ></CalendarDay>
      </div>
      <div v-else>
         <CalendarVFullCalendar
            :key="reload"
            @event-clicked="showSessionOverview"
         ></CalendarVFullCalendar>
      </div>

      <CommonSlideOver
         @close="showSessionInfo = false"
         v-if="showSessionInfo"
         :is-open="showSessionInfo"
      >
         <CalendarSessionDetail
            :session-id="sessionId"
            :show-action="!!sessionId"
            from-booking-page
            from-calendar
            @reload="reload = reload + 1"
            @close="showSessionInfo = false"
         ></CalendarSessionDetail>
      </CommonSlideOver>
   </TherapistManageAvailability>
</template>
<script setup>
   definePageMeta({
      layout: 'therapist',
      middleware: ['therapist'],
   });
   useHead({
      title: 'Calendar | Talktime.ae',
   });
   const reload = ref(1);

   const showSessionInfo = ref(false);
   const sessionId = ref(null);
   const sessionDetail = ref(null);
   const showSessionOverview = (sessionID) => {
      if (sessionID.id) {
         sessionDetail.value = sessionID;
         sessionId.value = sessionID.id;
         showSessionInfo.value = true;
      }
   };
   const showFullCalendar = ref(window.innerWidth < 768);
   const setShowFullCalender = () => {
      if (window.innerWidth < 768) showFullCalendar.value = false;
      else showFullCalendar.value = true;
   };
   onBeforeMount(() => {
      setShowFullCalender();
      window.addEventListener('resize', setShowFullCalender);
   });
   const { updateSelectedDate } = useTherapistAvailabilityStore();
   onUnmounted(() => {
      updateSelectedDate(null);
      window.removeEventListener('resize', setShowFullCalender);
   });
</script>
